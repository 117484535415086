import AddPost from "../pages/addPost";
import Dashboard from "../pages/dashboard";
import ProjectExports from "../pages/exports";
import Profile from "../pages/profile";
import Home from "../pages/home";
import Login from "../pages/loginNew";
import Register from "../pages/register";
import ForgotPwd from "../pages/forgotpwd";
import ResetPwdConfirmation from "../pages/resetpwdconfirmation";
import ResetPwd from "../pages/resetpwd";
import Project from "../pages/project";
import ProjectAssets from "../pages/projectAssets";
import ProjectParameters from "../pages/projectParameters";
import ProjectEditOverview from "../pages/projectEditOverview";
import Project3DViewer from "../pages/project3Dviewer";
import ProjectLabeled3DViewer from "../pages/projectLabel3Dviewer";
import EditProjectDetails from "../pages/projectEditDetails";
import projectDeliverables from "../pages/projectDeliverables";
import ProjectUploadFolder from "../pages/projectUploadFolders";
import ProjectViewList from "../pages/projectEditOverviewFolders";
import ProjectSecondLabel from "../pages/projectSecondLabel3Dviewer";
import UpdatePassword from "../pages/updatePassword";
import ProjectDeliverableOrtho from "../pages/projectDeliverablesOrtho";
import ProjectGeoLocated from "../pages/projectGeoLocated";
import Project3DViewer4 from "../pages/project3Dviewer4";

import Project3DViewer5 from "../pages/projectForthLabel3Dviewer";
import Project3DViewer6 from "../pages/projectFifthLabel3Dviewer";


const routes =[
    
    {
      path:'/',
      component: Login,
      isPrivate: false,
    },
    {
        path:'/login',
        component: Login,
        isPrivate: false,
    },
    {
        path:'/forgotpassword',
        component: ForgotPwd,
        isPrivate: false,
    },
    {
        path:'/forgotpasswordconfirmation',
        component: ResetPwdConfirmation,
        isPrivate: false,
    },
    {
        path:'/resetpwd/:token',
        component: ResetPwd,
        isPrivate: false,
    },
    {
        path:'/register',
        component: Register,
        isPrivate: false,
    },
    {
        path:'/dashboard',
        component: Dashboard,
        isPrivate: true,
    },
    {
        path:'/profile',
        component: Profile,
        isPrivate: true,    
    },
    {
        path:'/project',
        component: Project,
        isPrivate: true,
    },
    {
        path:'/project/:id/assets',
        component: ProjectUploadFolder,
        isPrivate: true,
    },
    {
        path:'/project/:id/upload/:folder',
        component: ProjectAssets,
        isPrivate: true,
    },
    {
        path:'/project/:id/parameters',
        component: ProjectParameters,
        isPrivate: true,
    },
    {
        path:'/project/:id/edit',
        component: ProjectViewList,
        isPrivate: true,
    },
    {
        path:'/project/:id/exports',
        component: ProjectExports,
        isPrivate: true,
    },
    {
        path:'/project/:id/view/:folder',
        component: ProjectEditOverview,
        isPrivate: true,
    },
    {
        path:'/project/:id/viewer',
        component: Project3DViewer,
        isPrivate: true,
    },
    {
        path:'/project/:id/labeledViewer',
        component: ProjectLabeled3DViewer,
        isPrivate: true,
    },
    {
        path:'/project/:id/viewer-model',
        component: Project3DViewer4,
        isPrivate: true,
    },
    {
        path:'/project/:id/viewer-model/5',
        component: Project3DViewer5,
        isPrivate: true,
    },
    {
        path:'/project/:id/viewer-model/6',
        component: Project3DViewer6,
        isPrivate: true,
    },
    {
        path:'/project/:id/geolocated',
        component: ProjectGeoLocated,
        isPrivate: true,
    },
    {
        path:'/project/:id/edit/details',
        component: EditProjectDetails,
        isPrivate: true,
    },
    {
        path:'/project/:id/deliverable',
        component: projectDeliverables,
        isPrivate: true,
    },
    {
        path:'/project/:id/labeledViewerOptional',
        component: ProjectSecondLabel,
        isPrivate: true,
    },
    {
        path:'/profile/change-password',
        component: UpdatePassword,
        isPrivate: true,
    },
    {
        path:'/project/:id/projectDeliverableOrtho',
        component: ProjectDeliverableOrtho,
        isPrivate: true,
    }
    
    
  ]
   
  export default routes
