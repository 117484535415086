import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import uploadImage from '../images/Upload.svg';
import axios from 'axios';
import { API_URL } from '../constants';
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2'
import { ToastContainer } from "react-toastr";
import { v4 as uuidv4 } from 'uuid';




const Dropzone =  ({stateChanger, folder, props, ...rest}) => {
  let container;
  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  let { id } = useParams();
  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps} = useDropzone({
    // accept: [ '*/*' ],
    //   // 'image/*', 
    //   // 'video/*',
    //   // '.bin',
    //   // '.sbf',
    //   // '.asc',
    //   // '.xyz',
    //   // '.neu',
    //   // '.pts',
    //   // '.las',
    //   // '.e57',
    //   // '.ptx',
    //   // '.fls',
    //   // '.fws',
    //   // '.dp',
    //   // '.pcd',
    //   // '.ply',
    //   // '.drc',
    //   // '.obj',
    //   // '.vtk',
    //   // '.stl',
    //   // '.off',
    //   // '.fbx',
    //   // '.dxf',
    //   // '.shp',
    //   // '.pdms',
    //   // '.pdmsmac',
    //   // '.mac',
    //   // '.geotiff',
    //   // '.out',
    //   // '.pv',
    //   // '.pn',
    //   // '.soi',
    //   // '.pov',
    //   // '.icm',
    //   // '.georef',
    //   // '.sx',
    //   // '.csv'
    // // ],
    onDrop: async acceptedFiles => {
      
      stateChanger(true)

      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
        uid: uuidv4()
      })));

      
      const url = `${API_URL}/projects/upload`;

      let requestArr = [];

      await asyncForEach(acceptedFiles, async (file) => {
        console.log('file',file)
        var formData = new FormData();
            formData.append('file', file);
            formData.append('projectId', id);
            formData.append('rootfolder', folder);
            console.log('formData',formData)
          requestArr.push(
              axios
                  .post(url,formData)
                  .then((rst) => {
                    console.log('rst',rst) 
                    var elem = document.getElementById(file.uid);
                    var html = elem.innerHTML;
                    elem.innerHTML = `<span class="upload-success" >Uploaded</span>`;
                  })
                  .catch((error) => { 
                    console.log('error', error);
                    var elem = document.getElementById(file.uid);
                    var html = elem.innerHTML;
                    elem.innerHTML = `<span class="upload-error" >Error uploading</span>`;
                  })
          );
      });

      Promise.all(requestArr).then(() => {
        console.log('resolved promise.all')
        stateChanger(false)
      });      

    }
  });
  
  const thumbs = files.map(file => (
    <div className="thumb" key={file.name}>
      <div className="thumbList">
      
        {file.path} - {file.size} bytes - <span id={file.uid}><span className="upload-pending" >Uploading</span></span>
      
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="container">
      <ToastContainer
        ref={ref => container = ref}
        className="toast-top-right"
      />
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <img src={uploadImage} />
        <br />
        <p>Drag and drop your files, or click the button to upload</p>
        <button className="btn primary-btn-active width50">Upload from computer</button>
      </div>
      <aside >
        {thumbs}
      </aside>
    </section>
  );
}

export default Dropzone