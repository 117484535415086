import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton,Form } from 'react-bootstrap';
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/header';
import closebtn from '../images/close-btn.svg';
import profilePlaceholder from '../images/user-profile-tools.svg';
import assetPlaceholder from '../images/projectDetails.svg';
import deleteImg from '../images/delete.svg';
import clockImg from '../images/sandclock.svg';
import { API_URL, ASSET_DIR, RESULT_DIR } from '../constants';
import { useAuthState, useAuthDispatch, logout } from '../context';
import ProjectMenu from '../components/projectMenu';
import chevronImg from '../images/chevron.svg';
import folderIcon from '../images/folderIcon.svg';
import downloadIcon from '../images/downloadarrow.svg';
import fileIcon from '../images/fileIconBlank.svg';
import excelIcon from '../images/excelIcon.svg';
import newtagIcon from '../images/newtag.svg';
import { saveAs } from '../library/saveAsCustom'
import Thumbnail from '../components/thumbnails';
import moment from 'moment'


const Project = (props) => {

    let { id } = useParams(); 
    const [projectId, setProjectId] = useState("");
    const [assets, setAssets] = useState([]);

    const handleLogout = () => {
        
       window.location.href = '/login';
    }

    const handleEdit = () => {        
        window.location.href = `/project/${id}/edit/details`;
     }

     useEffect(() => {
        setProjectId(id);
        async function getAssetsByProject() {
            const data_obj = {
                projectId: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getResultsByProject`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr',hxr)
            setAssets(hxr.data.assets)            
        }

        getAssetsByProject();

    }, [])

    
    const [project, setProject] = useState([]);



    useEffect(() => {
        setProjectId(id);
        async function getProjectById() {
            const data_obj = {
                projectid: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getProjectById`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr',hxr)
            setProject(hxr.data.project)
        }
        getProjectById();
    }, [])

    const handleDeleteOne = async (id) => {
        console.log('id', id)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async (result) => {
            if (result.isConfirmed) {
        
                const data_obj = {
                    id: id
                }
                console.log('data_obj', data_obj);
                const config = {
                    headers: {
                        'Content-Type': 'x-www-form-urlencoded',
                    },
                };
                const url = `${API_URL}/projects/deleteAssetById`;
        
                let hxr = await axios(url, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    data: data_obj
                })
                console.log('hxr',hxr.data)
                document.getElementById(`asset-${id}`).remove()
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                  )
            }
          })
    }

    const handleDeleteAll = async (id) => {
        console.log('id', id)
        Swal.fire({
            title: 'Are you sure?',
            text: "You will delete all your assets",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete All!'
          }).then(async (result) => {
            if (result.isConfirmed) {
        
                const data_obj = {
                    id: id
                }
                console.log('data_obj', data_obj);
                const config = {
                    headers: {
                        'Content-Type': 'x-www-form-urlencoded',
                    },
                };
                const url = `${API_URL}/projects/deleteAssetById`;
        
                let hxr = await axios(url, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    data: data_obj
                })
                console.log('hxr',hxr.data)
                document.getElementById(`asset-${id}`).remove()
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                  )
            }
          })
    }

    const downloadImageTest = () => {
        saveAs('https://api.aren-qa.com/tmp/results/E10-S-PIER (final_ortho).png', 'E10-S-PIER (final_ortho).png') // Put your image url here.
      }

      const downloadImageTest1 = () => {
        saveAs('https://api.aren-qa.com/tmp/results/E10-S-PIER.tif', 'E10-S-PIER.tif') // Put your image url here.
      }
      const downloadImageTest2 = () => {
        saveAs('https://api.aren-qa.com/tmp/results/E10-S-PIER (Binary Crack Map).jpg', 'E10-S-PIER (Binary Crack Map).jpg') // Put your image url here.
      }

      const downloadImage = (filename, name) => {
        if(!name){
            name = filename
        }
        const filesaver = saveAs(`${API_URL}/${RESULT_DIR}/${filename}`, name, {}, 
        ()=>{
            console.log('start')
            Swal.fire({
                title: '<div class="center-top">Please wait as we are preparing your download</div>',
                html: '<i class="fas fa-spinner fa-spin spinner"></i>',
                allowOutsideClick: false,
                showConfirmButton: false ,
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                }
              })
        },
        ()=>{
            console.log('done')
            setTimeout(()=>{
                Swal.close()
            },2500)
            
        }) // Put your image url here.
        // filesaver.onwriteend = function() {console.log('callback')}
      }

      const handleDownloadAll = () => {
        Swal.fire({
            title: '<div class="center-top">Please wait as we are preparing your download</div>',
            html: '<i class="fas fa-spinner fa-spin spinner"></i>',
            allowOutsideClick: false,
            showConfirmButton: false ,
            showClass: {
            popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
            }
        })
        async function zipDeliverables() {
            const data_obj = {
                projectId: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/zipDeliverables`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr',hxr)
            if(hxr.data.success){
                const filesaver = saveAs(`${API_URL}/tmp/zip/assets/${hxr.data.directory}/${hxr.data.downloadName}`, hxr.data.downloadName, {}, 
                ()=>{
                    console.log('start')
                    
                },
                ()=>{
                    console.log('done')
                    setTimeout(()=>{
                        Swal.close()
                    },2500)
                    
                })
            }
            // setAssets(hxr.data.assets)            
        }

        zipDeliverables();
      }
    //   const handleDownloadAll = () => {
    //     console.log('asset', assets)
    //     assets.map(asset => {
    //         downloadImage(asset.filename, asset.name)
    //     })
    //   }

    return (
        <>
            <Header />
            <Container >
                <Row>
                    <Col md="12" >
                        <form className="action-link-auth">
                        <div className="project-dashboard deliverables">
                            <div className="profile-actions">
                                <div className="project-actions-left left padding10px"> 
                                <Link className="no-link" to="/dashboard">All Projects</Link> <span> <img src={chevronImg} width="15px" /> </span>  {project.name}
                                 </div>
                                <div className="project-actions-right right">
                                    <Button variant="btn btnSelected next-btn" onClick={handleEdit}>
                                        Project Details
                                    </Button>
                                </div>
                            </div>
                            <br />
                            <hr />
                           
                            <div className="project-left edit-assets w100">
                                <ProjectMenu active='deliverables' id={id} isDemo={project.isDemo}  />
                                <div className="project-actions-right right">    
                                {project.isDemo ? (                                
                                    <Link   className="btn outline-secondary primary-btn-active" 
                                            to={{ pathname:"https://aren-assets-public.s3.amazonaws.com/project-deliverables.zip"}}
                                            target="_blank" 
                                            download>
                                        Download All
                                    </Link> 
                                    ) : (
                                        <>
                                        {assets && assets.length > 0 ? (
                                        <Button className="btn outline-secondary primary-btn-active"
                                            onClick={handleDownloadAll}
                                            download>
                                            Download All
                                        </Button> ) : ''}
                                        </>
                                    ) }
                                </div>
                                
                                <hr className="project-hr" />
                                {project.isDemo ? (
                                       <>
                                       {assets && assets.length > 0 ? (
                                           assets.map((asset)=>{
                                               return(
                                                   <div  >
                                                       <div className="deliverablAssetsRow"  >
                                                           <span className="thumbnail">
                                                               <Thumbnail image={asset.filename} type="results" />
                                                           </span>
                                                           <span className="assetNameFile">{asset.name}  <img className="newTag" src={newtagIcon} width="40px" /></span>
                                                           <span className="assetDate">{moment(asset.updatedDate).format('DD. MMM YYYY')}</span>
                                                           <span className="assetDownload" >    
                                                           <a onClick={()=>{downloadImage(asset.filename, asset.name)}} href="#" ><img src={downloadIcon} width="25px" /></a>                                          
                                                           </span>
                                                       </div>                                                        
                                                   </div>
                                               )
                                           })
                                       ) : (
                                           <div className="deliverableContainer">
                                               <div className="no-deliverables">
                                                   <div className="no-deliverables-content">
                                                       <img src={clockImg} width="75px" className="rotate" />
                                                       <h5>Hang in there. We’re still working on it.</h5>
                                                       <p>You will receive the notification when your files are ready!</p>
                                                   </div>    
                                               </div>
                                           </div>
                                       )}
                                       
                                       </>
                                    ) : (
                                        <>
                                        {assets && assets.length > 0 ? (
                                            assets.map((asset)=>{
                                                return(
                                                    <div  >
                                                        {/* <div className="deliverablAssetsRow"  >
                                                            <span className="thumbnail">
                                                                <img src={folderIcon} width="65px" />
                                                            </span>
                                                            <span className="assetName">Analyzed Orthomosaic</span>
                                                            <span className="assetCount">1 File</span>
                                                            <span className="assetDate">13. Abr 2022</span>
                                                            <span className="assetDownload" >    
                                                            <Link to={{ pathname:"https://aren-assets-public.s3.amazonaws.com/E10-S-PIER+(final_ortho).png"}} target="_blank" download><img src={downloadIcon} width="25px" /></Link>                                            
                                                            </span>
                                                        </div> */}
                                                        <div className="deliverablAssetsRow"  >
                                                            <span className="thumbnail">
                                                                <Thumbnail image={asset.filename} type="results" />
                                                            </span>
                                                            <span className="assetNameFile">{asset.name}  <img className="newTag" src={newtagIcon} width="40px" /></span>
                                                            <span className="assetDate">{moment(asset.updatedDate).format('DD. MMM YYYY')}</span>
                                                            <span className="assetDownload" >    
                                                            <a onClick={()=>{downloadImage(asset.filename, asset.name)}} href="#" ><img src={downloadIcon} width="25px" /></a>                                          
                                                            </span>
                                                        </div>                                                        
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div className="deliverableContainer">
                                                <div className="no-deliverables">
                                                    <div className="no-deliverables-content">
                                                        <img src={clockImg} width="75px" className="rotate" />
                                                        <h5>Hang in there. We’re still working on it.</h5>
                                                        <p>You will receive the notification when your files are ready!</p>
                                                    </div>    
                                                </div>
                                            </div>
                                        )}
                                        
                                        </>
                                    )}
                                
                            </div>
                            {/* <hr />
                            <div className="project-dashboard-footer">
                                <div className="project-footer-left project-footer-column">
                                    <span className="project-step project-step-active">1</span>
                                    <span className="project-step-text">Add Project details</span>
                                </div>
                                <div className="project-footer-middle project-footer-column">
                                    <span className="project-step">2</span>
                                    <span className="project-step-text">Upload your files</span>
                                </div>
                                <div className="project-footer-right project-footer-column">
                                    <span className="project-step">3</span>
                                    <span className="project-step-text">Set Parameters</span>
                                </div>
                            </div> */}
                        </div>
                    </form>    
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default Project;