import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Navbar, Nav, NavDropdown, Table } from 'react-bootstrap';
import axios from 'axios';
import Header from '../components/header';
import { API_URL, CUSTOMER_STATUS } from '../constants';
import RenderImage from '../components/renderImages';
import moment from 'moment-timezone';
import { ParseStatus } from '../utils/status';
import ProjectMenu from '../components/projectMenu';
import { Link, useParams } from "react-router-dom";
import downloadIcon from "../images/downloadarrow.svg";

function parseFileName(fileName) {
    let fourthUnderscoreIndex = fileName.indexOf('_', fileName.indexOf('_') + 1);
    fourthUnderscoreIndex = fileName.indexOf('_', fourthUnderscoreIndex + 1);
    fourthUnderscoreIndex = fileName.indexOf('_', fourthUnderscoreIndex + 1);
    
    // Return the substring from the start of the string up to the fourth underscore index
    return fileName.substring(0, fourthUnderscoreIndex);
}

const userTimeZone = moment.tz.guess();

const Dashboard = (props) => {
    
    const [projects, setProjects] = useState([]);
    const [invitedProjects, setinvitedProjects] = useState([]);
    const [filters, setFilters] = useState({});
    const [unmutableProject, setUnmutableProject] = useState({});
    const [exports, setExports] = useState([]);

    const saved = localStorage.getItem('currentUser');
    const initialValue = JSON.parse(saved);
    const currentUser  = initialValue.user.email;

    const handleProject = async (e) => {
        e.preventDefault()
        props.history.push('/project') //navigate to dashboard on success
    }
    const handleEdit = async (id) => {
        // e.preventDefault()
        props.history.push(`/project/${id}/edit`) //navigate to dashboard on success
    }

    const handleStatusChange = async (e) => {
        console.log('e', e.target.value);
        if(e.target.value === 'all'){
            const data_obj = {
                email: currentUser
            }
            const url = `${API_URL}/projects/getByEmail`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            let projects = [];
            hxr.data.projects.map( (project) => {
                project.vstatus = ParseStatus(project.status);
                projects.push(project);
            })

            console.log('setProjects hxr',hxr)
            setProjects(projects)
        }else{
            console.log('unmutableProject',unmutableProject)
            let allProjects = [...unmutableProject];
            console.log('allProjects', allProjects)
            const filtered = allProjects.filter((project) => {
                console.log('project', project.vstatus)
                return project.vstatus === e.target.value;
            })
            console.log('projects', filtered)
            setProjects(filtered);
        }
        
    }

    
    let { id } = useParams();

    const [project, setProject] = useState([]);
    const [iframeLink, setIframeLink] = useState([]);

    useEffect(() => {
        async function getProjectById() {
            const data_obj = {
                projectid: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getProjectById`;

            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr', hxr)
            setProjects(hxr.data.project)
            
            
        }
        getProjectById()
    }, [])
    
    useEffect(() => {
        async function getExportsByProjectId() {
            const data_obj = {
                projectid: id
            }
            
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/deliverableAsset/getExportsByProjectId`;

            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('exports hxr', hxr.data.exports)
            setExports(hxr.data.exports)            
            
            
        }
        getExportsByProjectId()
    }, [])

    return (
        <>
            <Header />
            <Container >
                <Row>
                    <Col md="12" >
                        <div className="project-dashboard">
                        <div className="project-dashboard-actions">
                                <div className="project-actions-left left padding10px"> 
                                    My Exports
                                 </div>
                                <div className="project-actions-right right">
                                    <Button variant="btn primary-btn-active next-btn" onClick={handleProject}  >
                                        New Project
                                    </Button>
                                </div>
                        </div>
                            <ProjectMenu active='export' id={id}/>
                            <Table style={{marginTop: '20px'}}>
                                            <thead>
                                                <tr className="project-rows">
                                                    <th>Project Name</th>
                                                    <th>Orthomosaic</th>
                                                    <th>File Type</th>
                                                    <th>File Name</th>
                                                    <th>Date Created</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody className="project-tablebody">
                            {exports && exports.length > 0 ? 
                                exports.map(project=>{
                                    return(                                                                                    
                                        <tr>
                                            <td>{projects.name}</td>
                                            <td>{project.orthoName}</td>
                                            <td>{project.type}</td>
                                            <td>{parseFileName(project.name)}</td>
                                            <td>{moment(project.createdDate).tz(userTimeZone).format('dddd Do, MMMM YYYY')}</td>
                                            <td>
                                                <a 
                                                    style={{padding: '2px !important'}} 
                                                    className="btn primary-btn-active" 
                                                    href={project.url}
                                                    download
                                                >
                                                        Download
                                                </a>
                                            </td>
                                        </tr>                                    
                                    )}    
                                    ) : (
                                        
                                        <tr>
                                            <td colSpan={6}>No Exports Available</td>
                                        </tr>
                                    )}

                                            </tbody>    
                                        </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default Dashboard;
