import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown } from 'react-bootstrap';

import { useAuthState, useAuthDispatch, logout } from '../context'
import axios from 'axios';
import Moment from 'moment';

import thumbProfileimg from '../images/profilePictureDefault.svg';
import notificationImg from '../images/Notifications.svg';
import closeXImg from '../images/closeX.svg';

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { API_URL } from '../constants';


const Header = (props) => {

    const [state, setState] = useState({
        isPaneOpen: false,
        isPaneOpenLeft: false,
      });

    const userDetails = useAuthState()
    const dispatch = useAuthDispatch()

    const handleLogout = () => {
        logout(dispatch);
       window.location.href = '/login';
    }

    //load user details from local storage in react hooks 
    const [userEmail, setUserEmail] = useState("")
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('currentUser'))
        setUserEmail(userData.user.name)
    }, [])

    const [notification, setNotification] = useState(null)

    function objCompare(obj1, obj2){
        for (var [key, value] of Object.entries(obj1)) {
          for (var [k, v] of Object.entries(obj2)){
            if(k == key && v == value)
             console.log(true)
          }
        }
      }

    const [notificationRead, setNotificationRead] = useState(null)  
    const [notificationUnRead, setNotificationUnRead] = useState(null)  


    useEffect(() => {
        async function getAllNnotificationsByCustomer() {
            const data_obj = {
                email: userEmail
            }
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getAllNnotificationsByCustomer`;
            
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr.data.success',hxr.data.success)
            if(hxr.data.success && hxr.data.notifications.length > 0 ){
                // if(notification === null){
                //     setNotification(hxr.data.notifications[0])
                // }

                // if(objCompare(notification, hxr.data.notifications[0])){
                    
                // }
                // setNotification(hxr.data.notifications[0])
                console.log('hxr.data.notifications[0',hxr.data.notifications[0])
                if(hxr.data.notifications !== null){
                    setNotificationRead( hxr.data.notifications[0].filter(obj => {
                        return obj.status === 'read';
                    }))
                    setNotificationUnRead( hxr.data.notifications[0].filter(obj => {
                        return obj.status === 'unread';
                    }))
                }

            }
            console.log('notification', notification )
        }
        // setInterval( ()=>{ getAllNnotificationsByCustomer()}, 2000)
        getAllNnotificationsByCustomer()
        

    }, [])

    const handleMarkAsRead = (id) => {
        async function setReadNotification() {
            const data_obj = {
                id: id
            }
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/setReadNotification`;
            
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            if(hxr.data.success){}
        }
        setReadNotification()
    }

    return (
      <>        
      <SlidingPane
        className="notifications-panel"
        overlayClassName="some-custom-overlay-class"
        isOpen={state.isPaneOpen}
        width="35%"
        hideHeader={true}
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setState({ isPaneOpen: false });
        }}
      >
        <div className="notification_header">
            <span className="notification_header_item ring_icon">
                <img src={notificationImg}  />
            </span>
            <span className="notification_header_item header_item_title">
                <h3>Notifications</h3>
            </span>
            <span className="notification_header_item close">
                <img src={closeXImg} onClick={()=>{setState({ isPaneOpen: false });}} />
            </span>
        </div>
        <hr />
        {(notificationUnRead && notificationUnRead.length > 0) ? notificationUnRead.map((notification)=> (
            <div className="notification_box">
            <div className="date">{Moment(notification.createdAt).format('MMMM Do')}</div>
            <div className="message">{notification.data}</div>
            <div className="actions">
                
                <Button variant="btn primary-btn-active next-btn" onClick={null}>
                    Review
                </Button>
                <Button variant="link" className="plainlink" onClick={()=>{handleMarkAsRead(notification._id)}}>
                    Mark as read
                </Button>
            </div>
        </div>
        )):(
            <p>0 New Notifications</p>
        )}
        <hr />
        <div className="olderNotifications">
            {notificationRead ? (
                <>Older notifications</>
            ) : (
                <>No older notifications</>
            )}   
        </div>
        <hr />
        {notificationRead && notificationRead.map((notification)=> (
            <>
                <div className="readNotification_box">
                    <div className="date">{Moment(notification.createdAt).format('MMMM Do')}</div>
                    <div>{notification.data}</div>
                </div>
            </>
        ))}   
        
        
      </SlidingPane>
      
      <Navbar className="navTopBar" collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
          <Navbar.Brand href="/dashboard"><img src="/img/aren-logo.svg" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto" alignRight>
              </Nav>
              <Nav className="" alignRight>
                  
              <div className="pull-left">
                      <span className="notifications">
                        <img src={notificationImg} onClick={() => setState({ isPaneOpen: true })} />
                      </span>
                              
                    </div> 
              </Nav>
              <Nav>
              <NavDropdown title={
                <>
                  {userEmail}
                  <img className="thumbnail-image" 
                      src={thumbProfileimg} 
                      alt="user pic"
                  />                
                  </>
                  
                } id="collasible-nav-dropdown">
                      <NavDropdown.Item href="/profile">My Profile</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                  </NavDropdown>
              </Nav>
          </Navbar.Collapse>
      </Container>
  </Navbar>
  </>
      
    );
};
export default Header;

    