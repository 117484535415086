import React, { useState, useEffect } from 'react';
import { Container,Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import {Link} from "react-router-dom";
import { loginUser, useAuthState, useAuthDispatch } from '../context'
import Swal from 'sweetalert2'

const Home = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useAuthDispatch()
    const { loading, errorMessage } = useAuthState() 

    const handleLogin = async (e) => {
        e.preventDefault()
      let payload = {password, email}
      try {
            let response = await loginUser(dispatch, payload);
            if (!response.user) return
            props.history.push('/dashboard') //navigate to dashboard on success
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Incorrect Credentials',
                confirmButtonText: 'OK' ,
                confirmButtonColor: '#FDC20F',
                customClass:{
                    confirmButton: 'confirm-button-login'
                }
                
              })
        }
     }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter' || e.charCode == 13){
            handleLogin(e)
        }
      }

    return (
        <Container fluid>
            <Row>
                <Col md="3" >
                    <div className="auth-info">
                        <div className="logo">
                            <img src="/img/aren-logo.svg" />
                        </div>
                        <div className="re-box">
                            <h1>Welcome to Aren</h1>
                            <form>
                                <input 
                                    type="email" 
                                    name="email" 
                                    id="email" 
                                    className="email input margin-top-35" 
                                    placeholder="Your Email"
                                    required value={email} onChange={(e) => setEmail(e.target.value)} 
                                    onKeyPress={handleKeyPress}
                                />
                                <input 
                                    type="password" 
                                    name="password" 
                                    id="password" 
                                    className="password input margin-top-35" 
                                    placeholder="Your Password"
                                    required value={password} onChange={(e) => setPassword(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                />
                                <p>Forgot Password? <Link to="/forgotpassword">Reset now</Link></p>
                                <Button 
                                    variant="primary" 
                                    className="primary-btn margin-top-35"
                                    onClick={handleLogin} disabled={loading}
                                >Login</Button>
                            </form>
                        </div>
                        <div className="no-acc margin-top-275">
                        Don’t have an account? <Link to="/Register" className="link-primary ">Join now</Link>
                        </div>
                    </div>
                </Col>
                <Col md={{ span: 8, offset: 1 }} className="home-img"></Col>
            </Row>
        </Container>
    );
};
export default Home;