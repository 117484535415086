import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup, ToggleButton, Form } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/header';
import closebtn from '../images/close-btn.svg';
import profilePlaceholder from '../images/user-profile-tools.svg';
import { API_URL } from '../constants';
import { useAuthState, useAuthDispatch, logout } from '../context'
import { Link, useParams } from "react-router-dom";
import ProjectMenu from '../components/projectMenu';
import clockImg from '../images/sandclock.svg';

const ProjectSecondLabel = (props) => {

    let { id } = useParams();

    const [project, setProject] = useState([]);
    const [iframeLink, setIframeLink] = useState([]);

    useEffect(() => {
        async function getProjectById() {
            const data_obj = {
                projectid: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getProjectById`;

            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr', hxr)
            setProject(hxr.data.project)
            // console.log('API_URL', API_URL)

            console.log('hxr.data.project.niraidFifth', hxr.data.project.labeledNiraIdFifth)
            //if variable is not null and not empty
            if (hxr.data.project.labeledNiraIdFifth) {
                setIframeLink(`${API_URL}/iframe/${hxr.data.project.labeledNiraIdFifth}`)
            }

            console.log('iframe', iframeLink)
            
        }
        getProjectById()
    }, [])

    return (
        <>
            <Header />


            {/* <iframe data-nira-asset-id={project.niraid} className="niraEmbedAsset1" id="niraEmbedAsset1" ></iframe> */}

            {project && project.labeledNiraIdFifth ? (
                <>
                <Container className='niraContainer' >
                    <Row>
                        <Col >
                            <div className="fullscreen">
                                <form>
                                    <div >
                                        <iframe src={iframeLink} className="niraIframe" frameBorder="0" allow="fullscreen"></iframe>
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                    <ProjectMenu active='fifthLabeledviewer' id={id} isDemo={project.isDemo} />
                </Container>
                
                </>
            ) : (
                <Container >
                    <Row>
                        <Col md="12" >
                            <form>
                            <br />
                            <br />
                           
                            <div className="project-left edit-assets w100">
                            <ProjectMenu active='3dLabeledmodel' id={id} isDemo={project.isDemo} />
                                    <div className="deliverableContainer">
                                        <div className="no-deliverables">
                                            <div className="no-deliverables-content">
                                                <img src={clockImg} width="75px" className="rotate" />
                                                <h5>Hang in there. We’re still working on it.</h5>
                                                <p>You will receive the notification when your files are ready!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            )}

            

        </>
    );
};
export default ProjectSecondLabel;
