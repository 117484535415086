import React, { useState, useEffect } from 'react';
import { Container,Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import {Link} from "react-router-dom";
import { API_URL } from '../constants';

const BASE_URL = 'http://dev.aren-qa.com:3009';

const Register = (props) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleSubmit = async (e) => { 
        e.preventDefault();

        try{
            const config = {
                method: 'post',
                url: `${API_URL}/users/create`,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                data: {
                    name: name,
                    email: email,
                    password: password
                  }
                }
            
            const xhr = await axios(config).catch(error => {
                console.log('error', error);
                throw error;
            });
            props.history.push('/login') //navigate to dashboard on success
            console.log('user create success')
        }catch(e){
            console.log('error', e)
        }
        
        
    }

    return (
        <Container fluid>
            <Row>
                <Col md="3" >
                    <div className="auth-info">
                        <div className="logo">
                            <img src="/img/aren-logo.svg" />
                        </div>
                        <div className="re-box">
                            <h1>Welcome to Aren</h1>
                            <form >
                                <input 
                                    type="text" 
                                    name="fullname" 
                                    id="fullname" 
                                    className="fullname input margin-top-35" 
                                    placeholder="Full Name"
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <input 
                                    type="email" 
                                    name="email" 
                                    id="email" 
                                    className="email input margin-top-35" 
                                    placeholder="Company email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />                                
                                <input 
                                    type="password" 
                                    name="password" 
                                    id="password" 
                                    className="password input margin-top-35" 
                                    placeholder="Set Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                
                                <Button type="submit" variant="primary" className="primary-btn margin-top-35" onClick={handleSubmit}> Join now</Button>
                            </form>
                        </div>
                        <div className="no-acc margin-top-275">
                        Already have an account?  <Link to="/Login" className="link-primary">Log In</Link>
                        </div>
                    </div>
                </Col>
                <Col md={{ span: 8, offset: 1 }} className="register-img"></Col>
            </Row>
        </Container>
    );
};
export default Register;