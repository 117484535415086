import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Header from '../components/header';
import { API_URL } from '../constants';
import { useParams } from "react-router-dom";
import ProjectMenu from '../components/projectMenu';
import clockImg from '../images/sandclock.svg';

const ProjectLabel = () => {
    let { id } = useParams();
    const [project, setProject] = useState(null);
    const [iframeLink, setIframeLink] = useState('');

    // Fetch project data
    useEffect(() => {
        async function getProjectById() {
            try {
                const data_obj = { projectid: id };
                console.log('Fetching project with:', data_obj);

                const url = `${API_URL}/projects/getProjectById`;
                let response = await axios.post(url, data_obj, {
                    headers: { 'Content-Type': 'application/json' }
                });

                console.log('Project Response:', response.data);
                setProject(response.data.project);
            } catch (error) {
                console.error("Error fetching project:", error);
            }
        }

        getProjectById();
    }, [id]); // Runs when `id` changes

    // Set iframe link when project is loaded
    useEffect(() => {
        if (project?.isGeolocatedUrl) {
            setIframeLink(`https://geospacial-oms.aren-qa.com/${project.isGeolocatedUrl}.php`);
        } else {
            setIframeLink(`https://geospacial-oms.aren-qa.com/chicago.php`);
        }
    }, [project]); // Runs when `project` updates

    return (
        <>
            <Header />
            {console.log("DEBUG: project.isGeolocatedUrl =", project?.isGeolocatedUrl)}
            {console.log("DEBUG: iframeLink =", iframeLink)}

            {project && project.isGeolocated ? (
                <Container className='niraContainer'>
                    <Row>
                        <Col>
                            <div className="fullscreen">
                                <form>
                                    <div>
                                        <iframe
                                            allow="fullscreen"
                                            src={iframeLink}
                                            className="niraIframe"
                                            frameBorder="0">
                                        </iframe>
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                    <ProjectMenu active='3dLabeledmodel' id={id} isDemo={project.isDemo} />
                </Container>
            ) : (
                <Container>
                    <Row>
                        <Col md="12">
                            <form>
                                <br /><br />
                                <div className="project-left edit-assets w100">
                                    <ProjectMenu active='3dLabeledmodel' id={id} isDemo={project?.isDemo} />
                                    <div className="deliverableContainer">
                                        <div className="no-deliverables">
                                            <div className="no-deliverables-content">
                                                <img src={clockImg} width="75px" className="rotate" />
                                                <h5>Hang in there. We’re still working on it.</h5>
                                                <p>You will receive the notification when your files are ready!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
};

export default ProjectLabel;
