import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup, ToggleButton, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/header';
import addInvite from '../images/add-invite.svg';
import addIconButton from '../images/addIconButton.svg';
import profilePlaceholder from '../images/user-profile-tools.svg';
import chevronImg from '../images/chevron.svg';
import deleteInviteImg from '../images/deleteInvite.svg';
import assetPlaceholder from '../images/projectDetails.svg';
import { API_URL } from '../constants';
import deleteImg from '../images/delete.svg';
import folderBold from '../images/folderBold.svg';
import { useAuthState, useAuthDispatch, logout } from '../context';
import ProjectMenu from '../components/projectMenu';
import backarrow from '../images/backarrow.svg';


const Project = (props) => {

    let { id, folder } = useParams();
    const [projectId, setProjectId] = useState("");
    const [projectInvites, setProjectInvites] = useState([]);
    const [isProjectGuest, setIsProjectGuest] = useState(false);
    const [show, setShow] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [invite, setInvite] = useState("");
    const handleShow = () => setShow(true);
    const [project, setProject] = useState([]);

    const handleLogout = () => {

        window.location.href = '/login';
    }

    const handleEdit = () => {
        window.location.href = `/project/${id}/edit/details`;
    }

    const [assets, setAssets] = useState([]);

    const [email, setEmail] = useState(() => {
        const saved = localStorage.getItem('currentUser');
        const initialValue = JSON.parse(saved);
        return initialValue.user.email || "";
    });

    useEffect(() => {
        setProjectId(id);
        async function getAssetsByProject() {
            const data_obj = {
                projectId: id,
                rootfolder: folder
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getAssetsByProjectAndRootFolder`;

            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr', hxr)
            setAssets(hxr.data.assets)
        }

        getAssetsByProject();

    }, [])

    useEffect(() => {
        setProjectId(id);
        async function getProjectInviteByProjectId() {
            const data_obj = {
                projectId: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getProjectInviteByProjectId`;

            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr project invites', hxr)
            setProjectInvites(hxr.data.projects)
        }

        getProjectInviteByProjectId();

    }, [])


    useEffect(() => {
        setProjectId(id);
        async function getProjectById() {
            const data_obj = {
                projectid: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getProjectById`;

            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr', hxr)
            setProject(hxr.data.project)
            console.log('email', email)
            console.log('hxr.data.project.createdBy', hxr.data.project.createdBy)

            setIsProjectGuest(email !== hxr.data.project.createdBy);
            console.log('isProjectGuest', isProjectGuest)
        }
        getProjectById();
    }, [])

    const handleDeleteOne = async (id) => {
        console.log('id', id)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#fdc20f',
            cancelButtonColor: '#fdc20f',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {

                const data_obj = {
                    id: id
                }
                console.log('data_obj', data_obj);
                const config = {
                    headers: {
                        'Content-Type': 'x-www-form-urlencoded',
                    },
                };
                const url = `${API_URL}/projects/deleteAssetById`;

                let hxr = await axios(url, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    data: data_obj
                })
                console.log('hxr', hxr.data)
                document.getElementById(`asset-${id}`).remove()
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })
    }

    const handleDeleteAll = async (id) => {
        console.log('id', id)
        Swal.fire({
            title: 'Are you sure?',
            text: "You will delete all your project",
            showCancelButton: true,
            confirmButtonColor: '#fdc20f',
            cancelButtonColor: '#585858',
            confirmButtonText: 'Yes, Delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {

                const data_obj = {
                    id: id
                }
                console.log('data_obj', data_obj);
                const config = {
                    headers: {
                        'Content-Type': 'x-www-form-urlencoded',
                    },
                };
                const url = `${API_URL}/projects/deleteProject`;

                let hxr = await axios(url, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    data: data_obj
                })
                // console.log('hxr',hxr.data)
                // document.getElementById(`asset-${id}`).remove()
                Swal.fire({
                    title: 'Deleted!',
                    text: "Your project has been deleted.",
                    showCancelButton: false,
                    confirmButtonColor: '#fdc20f',
                    confirmButtonText: 'Go to the Dashboard!',
                    allowOutsideClick: false
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        window.location.href = '/dashboard';
                    }
                })
            }
        })
    }

    const handleDeleteInvite = async (_id_) => {
        console.log('id', _id_)
        // console.log('new state', )

        const data_obj = {
            id: _id_
        }
        console.log('data_obj', data_obj);
        const config = {
            headers: {
                'Content-Type': 'x-www-form-urlencoded',
            },
        };
        const url = `${API_URL}/projects/deleteProjectInviteById`;

        let hxr = await axios(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: data_obj
        })
        // console.log('hxr',hxr.data)
        if (hxr && hxr.data.success) {
            console.log('deleted')
            setProjectInvites(projectInvites.filter(invite => invite._id !== _id_))
        }
    }


    const handleClose = () => {
        setShow(false)
        setShowEmailError(false);
    };




    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    //Create Project
    const handleSubmitInvite = async (e) => {
        e.preventDefault();

        try {
            if (validateEmail(invite)) {
                console.log('valid')
                const data = {
                    projectId: id,
                    invitedEmail: invite,
                    userEmail: email
                }
                const config = {
                    method: 'post',
                    url: `${API_URL}/projects/createInvite`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    },
                    data: data
                }

                const xhr = await axios(config).catch(error => {
                    console.log('error', error);
                    throw error;
                });

                console.log('create invite', xhr)

                if (xhr && xhr.data.success) {
                    setProjectInvites(projectInvites => [data, ...projectInvites]);
                    handleClose()
                }
                // props.history.push('/login') //navigate to dashboard on success
                // console.log('user create success')
            } else {
                console.log('invalid')
                setShowEmailError(true)
            }

        } catch (e) {
            console.log('error', e)
        }


    }

    const [folderName, setFolderName] = useState('');

    useEffect(() => {
        console.log('folder', folder)
        const actions = ['images360', 'imagesFishLens', 'imagesTypical', 'videos360', 'videosFishLens', 'videosTypical', 'laserScan', 'infraRed'];
        //if not in array
        if (!actions.includes(folder)) {
            window.location.href = '/';
        } else {
            const folderNameStr = {
                images360: 'Images - 360 degree',
                imagesFishLens: 'Images - Fisheye lens',
                imagesTypical: 'Images - Regular',
                videos360: 'Videos - 360 degree',
                videosFishLens: 'Videos - Fisheye lens',
                videosTypical: 'Videos - Regular',
                laserScan: 'Laser Scan',
                infraRed: 'Infrared',
            }
            setFolderName(folderNameStr[folder]);
        }
    }, [])

    const handleBack = () => {
        window.location.href = `/project/${id}/edit`
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Give access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form >
                        <div className="form-group">
                            <label htmlFor="name" className="label-custom">Invite email</label>
                            <input
                                type="email"
                                name="name"
                                id="name"
                                onChange={(e) => setInvite(e.target.value)}
                                autoComplete="off"
                                className={`input `}
                            // placeholder="Your Password"
                            // {...register('invite')}
                            // required value={password} onChange={(e) => setPassword(e.target.value)}
                            />
                            {showEmailError ? (
                                <div className="invalid-feedback always-display">Invalid Email</div>
                            ) : ""}

                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="btn primary-btn-active next-btn" onClick={handleSubmitInvite}>
                        Give Access
                    </Button>
                    <Button variant="btn btnSelected next-btn" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Header />
            <Container >
                <Row>
                    <Col md="12" >
                        <form className="action-link-auth">
                            <div className="project-dashboard">
                                <div className="profile-actions">
                                    <div className="project-actions-left left padding10px">
                                        <Link className="no-link" to="/dashboard">All Projects</Link>
                                        <span> <img src={chevronImg} width="15px" />  </span>
                                        {project.name}
                                        <span> <img src={chevronImg} width="15px" />  </span>
                                        <span style={{ marginRight: "10px" }}> <img src={folderBold} width="25px" /></span>
                                        {folderName}
                                    </div>
                                    <div className="project-actions-right right">
                                        {!isProjectGuest ? (
                                            <Button variant="outline-secondary" onClick={() => { handleDeleteAll(projectId) }}>
                                                <img src={deleteImg} width="25px" />
                                            </Button>
                                        ) : ""}
                                    </div>
                                </div>
                                <br />
                                <hr />

                                <div className="project-left edit-assets w100">
                                    <div className="project-actions-right right">


                                    </div>
                                    <div className="editWrapper">
                                        <div className='w5 v-align-top padding-top-20px'>
                                            <img src={backarrow} alt="backarrow" onClick={handleBack} style={{ "cursor": 'pointer' }} />
                                        </div>
                                        <div className='w95'>
                                            {assets && assets.length > 0 ? (
                                                assets.map((asset) => {
                                                    return (
                                                        <div className="assetsRow" key={asset._id} id={`asset-${asset._id}`} >
                                                            <span className="thumbnail">
                                                                <img src={assetPlaceholder} width="25px" />
                                                                {/* <img src={`${API_URL}/tmp/my-uploads/${asset.filename}`} width="75px" height="75px" /> */}
                                                            </span>
                                                            <span className="assetName" style={{ marginLeft: "45px" }}>{asset.name}</span>
                                                            <span className="assetType"></span>
                                                            {/* <span className="assetUploaded" onClick={()=>handleDeleteOne(asset._id)}>
                                                            <img src={deleteImg} width="20px" />
                                                        </span> */}
                                                        </div>
                                                    )
                                                })
                                            ) : (
                                                <div>No Assets has been uploaded.</div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                                {/* <hr />
                            <div className="project-dashboard-footer">
                                <div className="project-footer-left project-footer-column">
                                    <span className="project-step project-step-active">1</span>
                                    <span className="project-step-text">Add Project details</span>
                                </div>
                                <div className="project-footer-middle project-footer-column">
                                    <span className="project-step">2</span>
                                    <span className="project-step-text">Upload your files</span>
                                </div>
                                <div className="project-footer-right project-footer-column">
                                    <span className="project-step">3</span>
                                    <span className="project-step-text">Set Parameters</span>
                                </div>
                            </div> */}
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default Project;