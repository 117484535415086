import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Navbar, Nav, NavDropdown, Table } from 'react-bootstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import Header from '../components/header';
import { API_URL, CUSTOMER_STATUS } from '../constants';
import RenderImage from '../components/renderImages';
import Moment from 'moment';
import { ParseStatus } from '../utils/status';

const Dashboard = (props) => {
    
    const [projects, setProjects] = useState([]);
    const [invitedProjects, setinvitedProjects] = useState([]);
    const [filters, setFilters] = useState({});
    const [unmutableProject, setUnmutableProject] = useState({});

    const saved = localStorage.getItem('currentUser');
    const initialValue = JSON.parse(saved);
    const currentUser  = initialValue.user.email;

    const handleProject = async (e) => {
        e.preventDefault()
        props.history.push('/project') //navigate to dashboard on success
    }
    const handleEdit = async (id) => {
        // e.preventDefault()
        props.history.push(`/project/${id}/edit`) //navigate to dashboard on success
    }

    const handleStatusChange = async (e) => {
        console.log('e', e.target.value);
        if(e.target.value === 'all'){
            const data_obj = {
                email: currentUser
            }
            const url = `${API_URL}/projects/getByEmail`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            let projects = [];
            hxr.data.projects.map( (project) => {
                project.vstatus = ParseStatus(project.status);
                projects.push(project);
            })

            console.log('setProjects hxr',hxr)
            setProjects(projects)
        }else{
            console.log('unmutableProject',unmutableProject)
            let allProjects = [...unmutableProject];
            console.log('allProjects', allProjects)
            const filtered = allProjects.filter((project) => {
                console.log('project', project.vstatus)
                return project.vstatus === e.target.value;
            })
            console.log('projects', filtered)
            setProjects(filtered);
        }
        
    }

    

    useEffect(() => {
        async function getProjectsByUserId() {
            const data_obj = {
                email: currentUser
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getByEmail`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            let projects = [];
            hxr.data.projects.map( (project) => {
                project.vstatus = ParseStatus(project.status);
                projects.push(project);
            })

            console.log('setProjects hxr',hxr)
            setProjects(projects)
            setUnmutableProject(projects)
        }

        getProjectsByUserId();

    }, [])

    useEffect(() => {
        async function getInvitedProjects() {
            const data_obj = {
                email: currentUser
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getInvitedProjects`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('getInvitedProjects hxr',hxr)
            setinvitedProjects(hxr.data.projects)
        }

        getInvitedProjects();

    }, [])

    
// <option value="1">Bridge</option>
// <option value="2">Building Facade</option>
// <option value="3">Cooling Tower</option>
// <option value="4">Industrial Chimney</option>
// <option value="5">Dam</option>
// <option value="6">Levee</option>
// <option value="7">Culvert</option>

    const parseAssetType = (assetId) => {
        let type = '-';
        switch(assetId){
            case '1':
                type = 'Bridge';
                break; 
            case '2':
                type = 'Building Facade';
            break; 
            case '3':
                type = 'Cooling Tower';
            break; 
            case '4':
                type = 'Industrial';
                break; 
            case '5':
                type = 'Dam';
                break; 
            case '6':
                type = 'Levee';
                break; 
            case '7':
                type = 'Culvert';
                break;     
            case '9':
                type = 'Runway';
                break;     
            case '8':
                type = 'Tunnel';
                break;     
            case '10':
                type = 'Road';
                break; 
            case '11':
                type = 'Wind Turbine';
                break;         
               
        }
        return type;
    }

    return (
        <>
            <Header />
            <Container >
                {/* <div className="dashboard-banner">
                    <div className="logo">
                        
                    </div>
                    <div className="alerts"></div>
                    <div className="profile"></div>
                </div> */}
                <Row>
                    <Col md="12" >
                        <div className="project-dashboard">
                        <div className="project-dashboard-actions">
                                <div className="project-actions-left left padding10px"> 
                                    All Projects 
                                 </div>
                                <div className="project-actions-right right">
                                    <Button variant="btn primary-btn-active next-btn" onClick={handleProject}  >
                                        New Project
                                    </Button>
                                </div>
                        </div>
                        <div>
                            {/* <Col xs="8"></Col> */}
                            <Col md={{ span: 3, offset: 9 }}>
                                <Form.Select size="lg" className="custom-select" name="status"  onChange={(e) => handleStatusChange(e)}>
                                <option value="all">All</option>
                                <option value="Processing">Processing</option>
                                <option value="3D Model Published">3D Model Published</option>
                                <option value="Ask to Reshoot Data Due to Unsatisfactory Resolution">Ask to Reshoot Data Due to Unsatisfactory Resolution</option>
                                <option value="Data Reshooting in Progress">Data Reshooting in Progress</option>
                                <option value="Completed">Completed</option>                                
                                <option value="Draft">Draft</option>                                
                                </Form.Select>
                            </Col>  
                            <br />
                            <hr />
                            <br />
                        </div>
                            {projects && projects.length > 0 ? (
                            <Table>
                                <thead>
                                    <tr className="project-rows">
                                        <th style={{width:"52%"}}>Project Name</th>
                                        <th>Project Status</th>
                                        <th>Asset Type</th>
                                        <th>Date Created</th>
                                    </tr>
                                </thead>
                                <tbody className="project-tablebody">
                                
                                   { projects.map(project=>{
                                        return(
                                            <tr className="clickable" onClick={()=>handleEdit(project._id)}>
                                                <td>
                                                    <div className="left w25">
                                                        <RenderImage  projectType={project.assetsType} size="125"/>
                                                    </div>
                                                    <div className="left w50">
                                                        <span className="project-title">{project.name}</span> < br/>
                                                        <span className="project-location">{project.address}, {project.city}, {project.state}, {project.country}</span>
                                                    </div>
                                                    
                                                </td>
                                                <td><span className="projectStatus">{ ParseStatus(project.status) }</span></td>
                                                <td>{parseAssetType(project.assetsType)}</td>
                                                <td>{Moment(project.createdDate).format('D, MMMM yy')}</td>
                                            </tr>
                                        )
                                    })}
                              
                                </tbody>
                            </Table>
                            ) : ""}

                            {invitedProjects && invitedProjects.length > 0 ? (
                            <>
                            <div className="project-dashboard-actions">
                                <div className="project-actions-left left"> 
                                    My Project Invitations
                                </div>
                            </div>
                            <Table>
                                <thead>
                                    <tr className="project-rows">
                                        <th style={{width:"52%"}}>Project Name</th>
                                        <th>Project Status</th>
                                        <th>Asset Type</th>
                                        <th>Date Created</th>
                                    </tr>
                                </thead>
                                <tbody className="project-tablebody">
                                
                                   { invitedProjects.map(project=>{
                                        return(
                                            <tr className="clickable" onClick={()=>handleEdit(project._id)}>
                                            <td>
                                                <div className="left w25">
                                                    <RenderImage  projectType={project.assetsType} size="125"/>
                                                </div>
                                                <div className="left w50">
                                                    <span className="project-title">{project.name}</span> < br/>
                                                    <span className="project-location">{project.address}, {project.city}, {project.state}, {project.country}</span>
                                                </div>
                                                
                                            </td>
                                            <td><span className="projectStatus">{project.status}</span></td>
                                            <td>{parseAssetType(project.assetsType)}</td>
                                            <td>{Moment(project.createdDate).format('D, MMMM yy')}</td>
                                        </tr>
                                        )
                                    })}
                              
                                </tbody>
                            </Table>
                            </>
                            ) : ""}
                            
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default Dashboard;
