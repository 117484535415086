import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton,Form } from 'react-bootstrap';
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import Header from '../components/header';
import { API_URL, VIEWER_URL } from '../constants';
import ProjectMenu from '../components/projectMenu';
import chevronImg from '../images/chevron.svg';

import FullscreenIcon from '@mui/icons-material/Fullscreen';



const ProjectDeliverableOrtho = (props) => {

    let { id } = useParams();     
    const [orthoList, setOrthoList] = useState([]);
    const [project, setProject] = useState([]);
    const [iframeLink, setIframeLink] = useState('');

    const handleLogout = () => {        
       window.location.href = '/login';
    }

    const handleEdit = () => {        
        window.location.href = `/project/${id}/edit/details`;
     }

     useEffect(() => {

        async function getDeliverableOrthoByProject() {
            const data_obj = {
                projectId: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/deliverableAsset/getDeliverableAssetsByProjectId`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            // console.log('getDeliverableOrthoByProject hxr',hxr)
            setOrthoList(hxr.data.deliverableAsset) 
            // console.log('setOrthoList', orthoList)           
        } getDeliverableOrthoByProject()

        // orthoList.map(ortho => console.log('ortho', ortho) );

    }, [])

    
    // const [project, setProject] = useState([]);



    useEffect(() => {
        // setProjectId(id);
        async function getProjectById() {
            const data_obj = {
                projectid: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getProjectById`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr',hxr)
            setProject(hxr.data.project)
        }
        getProjectById();
    }, [])

    const handleSelectChange = (event) => {
        console.log('event.target.value',event.target.value);
        if(event.target.value !== 0){
            setIframeLink(`${VIEWER_URL}/${event.target.value}`)
        }
    }
    const handleFullscreen = (link) => {
        window.open(link);
    }


    return (
        <>
            <Header />
            <Container >
                <Row>
                    <Col md="12" >
                        <form className="action-link-auth">
                        <div className="project-dashboard deliverables">
                            <div className="profile-actions">
                                <div className="project-actions-left left padding10px"> 
                                <Link className="no-link" to="/dashboard">All Projects</Link> <span> <img src={chevronImg} width="15px" /> </span>  {project.name}
                                 </div>
                                <div className="project-actions-right right">
                                    <Button variant="btn btnSelected next-btn" onClick={handleEdit}>
                                        Project Details
                                    </Button>
                                </div>
                            </div>
                            <br />
                            <hr />
                           
                            <div className="project-left edit-assets w100">
                                <ProjectMenu active='ortho' id={id} isDemo={project.isDemo} />
                                <div className="project-actions-right right">   
                                
                                <Form.Select size="lg" className="custom-select" name="assetType" onChange={handleSelectChange}>
                                    <option value="0">Select an orthomosaic</option>
                                    {orthoList && orthoList.map(ortho => 
                                        (
                                            <option value={ortho._id}>{ortho.name}</option>
                                        )
                                    )}
                                </Form.Select>   
                                </div>
                                
                                <hr className="project-hr" />
                                                                 
                                    {iframeLink && iframeLink != '' ? (
                                        <>
                                        <div className='viewerIframeContainer'>   
                                            <button onClick={()=>{handleFullscreen(iframeLink)}}><FullscreenIcon /></button>
                                            <iframe src={iframeLink} className="viewerIframe" frameBorder="0" allow="fullscreen"></iframe>
                                        </div>
                                        </>
                                        
                                    ) : (
                                        <div className="no-deliverables-content center">
                                            <h5>Please select an orthomosaic.</h5>
                                        </div> 
                                    )}
                                 </div>
                        </div>
                    </form>    
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default ProjectDeliverableOrtho;
