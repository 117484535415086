import { API_URL } from '../constants';
import axios from "axios";

 
export async function loginUser(dispatch, loginPayload) {
    
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(loginPayload),
  };
 
  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    // let response = await fetch(`${API_URL}/users/login`, requestOptions);
    const config = {
        headers: {
          'Content-Type': 'x-www-form-urlencoded',
        },
      };
    const url = `${API_URL}/users/login`;
   
    let hxr = await axios(url, {
        method: 'POST',
        headers: {
        'content-type': 'application/json',
        },
        data: loginPayload
    })
    .catch(error => {
        console.log('error', error);
        throw error;
    });
    const data = hxr.data;
    console.log('data', data);

 
    if (data.user) {
      dispatch({ type: 'LOGIN_SUCCESS', payload: data });
      localStorage.setItem('currentUser', JSON.stringify(data));
      return data
    }
 
    dispatch({ type: 'LOGIN_ERROR', error: data.errors[0] });
    return;
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
  }
}
 
export async function logout(dispatch) {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
  }