import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton,Form } from 'react-bootstrap';
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/header';
import closebtn from '../images/close-btn.svg';
import profilePlaceholder from '../images/user-profile-tools.svg';
import { API_URL } from '../constants';
import { useAuthState, useAuthDispatch, logout } from '../context';
import chevronImg from '../images/chevron.svg';
import folderIcon from '../images/folderIcon.svg';
import downloadIcon from '../images/downloadarrow.svg';
import arrowIcon from '../images/Arrowright.svg';
import fileIcon from '../images/Iconaren-folder.svg';
import excelIcon from '../images/excelIcon.svg';
import newtagIcon from '../images/newtag.svg';
import { saveAs } from 'file-saver'
import Thumbnail from '../components/thumbnails';
import moment from 'moment'



const ProjectAssets = (props) => {
    
    let { id } = useParams();

    const [nextDisable, setNextDisable] = useState(true); 

    const [imagesFiles360, setImagesFiles360] = useState(0); 
    const [imagesFilesFishLens, setImagesFilesFishLens] = useState(0); 
    const [imagesFilesTypical, setImagesFilesTypical] = useState(0); 
    const [imagesFilesVideos, setImagesFilesVideos] = useState(0); 
    const [imagesFilesLaserScan, setImagesFilesLaserScan] = useState(0); 

    const [image360Count, setImage360Count] = useState(0);
    const [imagesFishLensCount, setImagesFishLensCount] = useState(0);
    const [imagesTypicalCount, setImagesTypicalCount] = useState(0);
    const [videos360Count, setVideos360Count] = useState(0);
    const [videosFishLensCount, setVideosFishLensCount] = useState(0);
    const [videosTypicalCount, setVideosTypicalCount] = useState(0);
    const [laserScanCount, setLaserScanCount] = useState(0);
    const [infraRedCameraCount, setInfraRedCameraCount] = useState(0);
    //  
    const [electricalCameraCount, setElectricalCameraCount] = useState(0);
    const [impactEchoCameraCount, setImpactEchoCameraCount] = useState(0);
    const [groundCameraCount, setGroundCameraCount] = useState(0);

    useEffect(() => {
        async function getAssetsCountByProject() {
            const data_obj = {
                projectId: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getAssetsCountByProject`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr getAssetsCountByProject',hxr)
  
            setImage360Count(hxr.data.assets.images360 ?? 0);
            setImagesFishLensCount(hxr.data.assets.imagesFishLens ?? 0);
            setImagesTypicalCount(hxr.data.assets.imagesTypical ?? 0);
            setVideos360Count(hxr.data.assets.videos360 ?? 0);
            setVideosFishLensCount(hxr.data.assets.videosFishLens ?? 0);
            setVideosTypicalCount(hxr.data.assets.videosTypical ?? 0);
            setLaserScanCount(hxr.data.assets.laserSca ?? 0);
            setInfraRedCameraCount(hxr.data.assets.infrared ?? 0);
            setElectricalCameraCount(hxr.data.assets.electrical ?? 0);
            setImpactEchoCameraCount(hxr.data.assets.impact ?? 0);
            setGroundCameraCount(hxr.data.assets.ground ?? 0)
            // setAssets(hxr.data.assets)            
        }

        getAssetsCountByProject();

    }, [])
    
    
      const [user, setUser] = useState(() => {
        const saved = localStorage.getItem('currentUser');
        const initialValue = JSON.parse(saved);
        return initialValue.user.email || "";
      });
    // const [email, setEmail] = useState(() => {
    //     const saved = localStorage.getItem('currentUser');
    //     const initialValue = JSON.parse(saved);
    //     return initialValue.user.email || "";
    //   });
    
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('New Password is required')
            .min(6, 'Password must be at least 6 characters'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        name: Yup.string()
            .required('Name is required')    
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    async function onSubmit(data) {
        console.log('onSubmit', data);
        console.log('user',user);
        const data_obj = {
            password: data.password,
            email: data.email,
            name: data.name,
            user: user
        }
        const config = {
            headers: {
                'Content-Type': 'x-www-form-urlencoded',
            },
        };
        const url = `${API_URL}/users/updateUser`;

        let hxr = await axios(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: data_obj
        })
        .catch(error => {
            console.log('error', error);
            Swal.fire({
                title: 'Error!',
                text: 'An error occured, please try again later',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        });

        // const _data = await hxr.data;
        // console.log('data', _data);

        if (hxr.data && hxr.data.success) {
            // localStorage.setItem('fwdpwd_email', data.user.email);
            // props.history.push('/login')
            //trigger swal  
            Swal.fire({
                title: 'Success!',
                text: 'Profile updated successfully',
                icon: 'success',
                confirmButtonText: 'OK'
            })
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'An error occured, please try again later',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        }

    }
    const dispatch = useAuthDispatch()

    const handleNext = () => {
       props.history.push(`/project/${id}/parameters`)
    }

    const [radioValue, setRadioValue] = useState('1');

    const radios = [
      { name: 'Imperial', value: '1' },
      { name: 'Metric', value: '2' },
    ];

    const handleUpload = (folder) => {
        window.location.href = `/project/${id}/upload/${folder}`;
    }
    

    return (
        <>
            <Header />
            <Container >
                <Row>
                    <Col md="12" >
                        <div className="project-dashboard">
                            <div className="profile-actions">
                                <div className="project-actions-left left"> 
                                    Upload your files
                                 </div>
                                <div className="project-actions-right right">
                                    <Button variant="btn primary-btn-active next-btn"  onClick={handleNext}>
                                        Next
                                    </Button>
                                </div>
                            </div>
                            <br />
                            <hr />
                            <div className="project-full-width upload-folders">
                                <div className="deliverablAssetsRow"  >
                                    <span className="thumbnail">
                                    <img src={fileIcon} alt="thumbnail" className="thumbnail-img" />
                                    </span>
                                    <span className="assetNameFile">Images - 360 degree</span>
                                    <span className="assetDate">{image360Count} Files</span>
                                    <span className="assetDownload" >    
                                    <a href="#" onClick={()=>handleUpload('images360')} ><img src={arrowIcon} width="25px" /></a>                                          
                                    </span>
                                </div> 
                                <div className="deliverablAssetsRow"  >
                                    <span className="thumbnail">
                                    <img src={fileIcon} alt="thumbnail" className="thumbnail-img" />
                                    </span>
                                    <span className="assetNameFile">Images - Fisheye lens</span>
                                    <span className="assetDate">{imagesFishLensCount} Files</span>
                                    <span className="assetDownload" >    
                                    <a href="#" onClick={()=>handleUpload('imagesFishLens')}><img src={arrowIcon} width="25px" /></a>                                          
                                    </span>
                                </div> 
                                <div className="deliverablAssetsRow"  >
                                    <span className="thumbnail">
                                    <img src={fileIcon} alt="thumbnail" className="thumbnail-img" />
                                    </span>
                                    <span className="assetNameFile">Images - Regular</span>
                                    <span className="assetDate">{imagesTypicalCount} Files</span>
                                    <span className="assetDownload" >    
                                    <a href="#" onClick={()=>handleUpload('imagesTypical')}><img src={arrowIcon} width="25px" /></a>                                          
                                    </span>
                                </div> 
                                <div className="deliverablAssetsRow"  >
                                    <span className="thumbnail">
                                    <img src={fileIcon} alt="thumbnail" className="thumbnail-img" />
                                    </span>
                                    <span className="assetNameFile">Videos - 360 degree</span>
                                    <span className="assetDate">{videos360Count} Files</span>
                                    <span className="assetDownload" >    
                                    <a href="#" onClick={()=>handleUpload('videos360')}><img src={arrowIcon} width="25px" /></a>                                          
                                    </span>
                                </div> 
                                <div className="deliverablAssetsRow"  >
                                    <span className="thumbnail">
                                    <img src={fileIcon} alt="thumbnail" className="thumbnail-img" />
                                    </span>
                                    <span className="assetNameFile">Videos - Fisheye lens</span>
                                    <span className="assetDate">{videosFishLensCount} Files</span>
                                    <span className="assetDownload" >    
                                    <a href="#" onClick={()=>handleUpload('videosFishLens')} ><img src={arrowIcon} width="25px" /></a>                                          
                                    </span>
                                </div> 
                                <div className="deliverablAssetsRow"  >
                                    <span className="thumbnail">
                                    <img src={fileIcon} alt="thumbnail" className="thumbnail-img" />
                                    </span>
                                    <span className="assetNameFile">Videos - Regular</span>
                                    <span className="assetDate">{videosTypicalCount} Files</span>
                                    <span className="assetDownload" >    
                                    <a href="#" onClick={()=>handleUpload('videosTypical')} ><img src={arrowIcon} width="25px" /></a>                                          
                                    </span>
                                </div> 
                                <div className="deliverablAssetsRow"  >
                                    <span className="thumbnail">
                                    <img src={fileIcon} alt="thumbnail" className="thumbnail-img" />
                                    </span>
                                    <span className="assetNameFile">Laser Scan</span>
                                    <span className="assetDate">{laserScanCount} Files</span>
                                    <span className="assetDownload" >    
                                    <a href="#" onClick={()=>handleUpload('laserScan')} ><img src={arrowIcon} width="25px" /></a>                                          
                                    </span>
                                </div> 
                                <div className="deliverablAssetsRow"  >
                                    <span className="thumbnail">
                                    <img src={fileIcon} alt="thumbnail" className="thumbnail-img" />
                                    </span>
                                    <span className="assetNameFile">Infrared Camera</span>
                                    <span className="assetDate">{infraRedCameraCount} Files</span>
                                    <span className="assetDownload" >    
                                    <a href="#" onClick={()=>handleUpload('infraRed')} ><img src={arrowIcon} width="25px" /></a>                                          
                                    </span>
                                </div>
                                <div className="deliverablAssetsRow"  >
                                    <span className="thumbnail">
                                    <img src={fileIcon} alt="thumbnail" className="thumbnail-img" />
                                    </span>
                                    <span className="assetNameFile">Ground Penetrating Radar</span>
                                    <span className="assetDate">{groundCameraCount} Files</span>
                                    <span className="assetDownload" >    
                                    <a href="#" onClick={()=>handleUpload('groundPenetrating')} ><img src={arrowIcon} width="25px" /></a>                                          
                                    </span>
                                </div>
                                <div className="deliverablAssetsRow"  >
                                    <span className="thumbnail">
                                    <img src={fileIcon} alt="thumbnail" className="thumbnail-img" />
                                    </span>
                                    <span className="assetNameFile">Impact Echo</span>
                                    <span className="assetDate">{impactEchoCameraCount} Files</span>
                                    <span className="assetDownload" >    
                                    <a href="#" onClick={()=>handleUpload('impactEco')} ><img src={arrowIcon} width="25px" /></a>                                          
                                    </span>
                                </div>
                                <div className="deliverablAssetsRow"  >
                                    <span className="thumbnail">
                                    <img src={fileIcon} alt="thumbnail" className="thumbnail-img" />
                                    </span>
                                    <span className="assetNameFile">Electrical Resistivity</span>
                                    <span className="assetDate">{electricalCameraCount} Files</span>
                                    <span className="assetDownload" >    
                                    <a href="#" onClick={()=>handleUpload('electricalResistivity')} ><img src={arrowIcon} width="25px" /></a>                                          
                                    </span>
                                </div> 
                            </div>
                            
                            <div className="project-dashboard-footer">
                                <div className="project-footer-left project-footer-column">
                                    <span className="project-step project-step-active">1</span>
                                    <span className="project-step-text">Add Project details</span>
                                </div>
                                <div className="project-footer-middle project-footer-column">
                                    <span className="project-step project-step-on">2</span>
                                    <span className="project-step-text">Upload your files</span>
                                </div>
                                <div className="project-footer-right project-footer-column">
                                    <span className="project-step">3</span>
                                    <span className="project-step-text">Set Parameters</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default ProjectAssets;