import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton } from 'react-bootstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/header';
import closebtn from '../images/close-btn.svg';
import profilePlaceholder from '../images/user-profile-tools.svg';
import { API_URL } from '../constants';
import { useAuthState, useAuthDispatch, logout } from '../context'
import backarrow from '../images/backarrow.svg';

const Profile = () => {
    const [name, setName] = useState(() => {
        const saved = localStorage.getItem('currentUser');
        const initialValue = JSON.parse(saved);
        return initialValue.user.name || "";
      });
      const [user, setUser] = useState(() => {
        const saved = localStorage.getItem('currentUser');
        const initialValue = JSON.parse(saved);
        return initialValue.user.email || "";
      });
    const [email, setEmail] = useState(() => {
        const saved = localStorage.getItem('currentUser');
        const initialValue = JSON.parse(saved);
        return initialValue.user.email || "";
    });
    const [unit, setUnit] = useState(() => {
        const saved = localStorage.getItem('metricUnit');
        return saved;
    });
    const [radioValue, setRadioValue] = useState('1');
    useEffect(()=>{
        console.log('unit', unit)
        console.log('radioValue', radioValue)
        if(unit == null){
            setRadioValue("1");
        }else{
            setRadioValue("0");
        }
        console.log('radioValue', radioValue)
    },[])

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('New Password is required')
            .min(6, 'Password must be at least 6 characters'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        name: Yup.string()
            .required('Name is required')    
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    async function onSubmit(data) {
        console.log('onSubmit', data);
        console.log('user',user);
        const data_obj = {
            password: data.password,
            email: data.email,
            name: data.name,
            user: user
        }
        const config = {
            headers: {
                'Content-Type': 'x-www-form-urlencoded',
            },
        };
        const url = `${API_URL}/users/updateUser`;

        let hxr = await axios(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: data_obj
        })
        .catch(error => {
            console.log('error', error);
            Swal.fire({
                title: 'Error!',
                text: 'An error occured, please try again later',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        });

        // const _data = await hxr.data;
        // console.log('data', _data);

        if (hxr.data && hxr.data.success) {
            // localStorage.setItem('fwdpwd_email', data.user.email);
            // props.history.push('/login')
            //trigger swal  
            Swal.fire({
                title: 'Success!',
                text: 'Profile updated successfully',
                icon: 'success',
                confirmButtonText: 'OK'
            })
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'An error occured, please try again later',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        }

    }
    const dispatch = useAuthDispatch()

    const handleGotoDashboard = () => {
       window.location.href = '/dashboard';
    }

    

    const handleMetricSystem = async (e)=>{
        // console.log('here')
        setRadioValue(e);
        let unit = 'metric';
        if(e === "1"){
            unit = 'metric';
        }else{
            unit = 'imperial';
        }
        const data_obj = {
            unit: unit,
            email: user
        }
        console.log('e',e)
        localStorage.setItem('metricUnit', e)

        // const saved = localStorage.getItem('currentUser');
        // const userStorage = JSON.parse(saved);
        // userStorage.user.unit = e;
        
        // const saveLocalStorage  = JSON.stringify(initialValue);
        //       window.localStorage.setItem('currentUser',saveLocalStorage)

        console.log('data_obj',data_obj)
        console.log('localstorage',localStorage.getItem('metricUnit'))
        // const config = {
        //     headers: {
        //         'Content-Type': 'x-www-form-urlencoded',
        //     },
        // };
        // const url = `${API_URL}/users/updateMetricSystem`;

        // let hxr = await axios(url, {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'application/json',
        //     },
        //     data: data_obj
        // })
        // .catch(error => {
        //     console.log('error', error);
        //     Swal.fire({
        //         title: 'Error!',
        //         text: 'An error occured, please try again later',
        //         icon: 'error',
        //         confirmButtonText: 'OK'
        //     })
        // });
        
    }

    const radios = [
      { name: 'Imperial', value: '1' },
      { name: 'Metric', value: '2' },
    ];

    const handleCheckMetric = (radio) => {
        console.log('radio',radio)
        console.log('radioValue',radioValue)
        return radioValue === radio.value
    }

    const handleChangePassword = async (e) => {
       window.location.href = '/profile/change-password';
    }

    return (
        <>
            <Header />
            <Container >
                <Row>
                    <Col md="12" >
                        <div className="project-dashboard">
                            <div className="profile-actions">
                                <div className="project-actions-left left"> 
                                    <Button variant="link" className="danger-link" onClick={handleGotoDashboard}><img src={backarrow} width="30px" /></Button>
                                 </div>
                                <div className="project-actions-right right">
                                    {/* <Button className="close-btn" onClick={handleLogout}>
                                        <img src={closebtn} />
                                    </Button> */}
                                </div>
                            </div>
                            <div className="profile-center">
                                <p className="left bottom-50">
                                    <img src={profilePlaceholder} />
                                </p>
                                <form onSubmit={handleSubmit(onSubmit)} className="action-link-auth">
                                    <div className="form-group">
                                        <label htmlFor="name" className="label-custom">Full Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            defaultValue={name}
                                            onChange={(e) => setName(e.target.value)}
                                            autoComplete="off"
                                            className={`input ${errors.name ? 'is-invalid' : ''}`}
                                            // placeholder="Your Password"
                                            {...register('name')}
                                        // required value={password} onChange={(e) => setPassword(e.target.value)}
                                        />
                                        {/* <div className="invalid-feedback">{errors.password?.message}</div> */}
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="email" className="label-custom">Change Email</label>
                                        <input
                                            type="text"
                                            name="email"
                                            id="email"
                                            disabled
                                            defaultValue={email}
                                            autoComplete="off"
                                            className={`input ${errors.email ? 'is-invalid' : ''}`}
                                            {...register('email')}
                                            // placeholder="Your Password"
                                            // {...register('password')}
                                        // required value={password} onChange={(e) => setPassword(e.target.value)}
                                        />
                                        {/* <div className="invalid-feedback">{errors.password?.message}</div> */}
                                    </div>

                                    
                                    <Button
                                        variant="primary"
                                        className="primary-btn update-profile-btn"
                                        type="submit"
                                    >Save changes</Button>
                                    {/* <hr /> */}
                                    <Button
                                        variant="dark"
                                        className="primary-btn-outline update-profile-btn"
                                        onClick={handleChangePassword}
                                    >Change Password</Button>
                                    <hr />
                                    <ButtonGroup>
                                        {radios.map((radio, idx) => (
                                        <ToggleButton
                                            key={idx}
                                            id={`radio-${idx}`}
                                            type="radio"
                                            variant="outline-warning"
                                            name="radio"
                                            value={radio.value}
                                            checked={
                                                localStorage.getItem('metricUnit') === radio.value
                                            }
                                            onChange={(e) =>{
                                                 handleMetricSystem(e.currentTarget.value)
                                                }
                                            }
                                        >
                                            {radio.name}
                                        </ToggleButton>
                                        ))}
                                    </ButtonGroup>
                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default Profile;