import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton,Form } from 'react-bootstrap';
import { API_URL } from '../constants';
import axios from 'axios';

const ProjectMenu = (props) => {
    const [labeledNiraIdText, setLabeledNiraidText] = useState('');
    const [labeledNiraIdSecondText, setLabeledNiraIdSecondText] = useState('');
    const [labeledNiraId, setLabeledNiraid] = useState('');
    const [labeledNiraIdSecond, setLabeledNiraIdSecond] = useState('');

    const [labeledNiraIdThirdText, setLabeledNiraIdThirdText] = useState('');
    const [labeledNiraIdThird, setLabeledNiraIdThird] = useState('');
    
    const [labeledNiraIdForth, setLabeledNiraIdForth] = useState('');
    const [labeledNiraIdFifth, setLabeledNiraIdFifth] = useState('');

    const [hasOrthoMosaics, setHasOrthoMosaics] = useState(false);

    const [firstTabText, setFirstTabText] = useState('');
    const [secondTabText, setSecondTabText] = useState('');
    const [thirtdTabText, setThirtdTabText] = useState('');
    const [forthTabText, setForthTabText] = useState('');
    const [fifthTabText, setFifthTabText] = useState('');

    const [isGeolocated, setIsGeolocated] = useState(true);
    const [hasExports, setHasExports] = useState(false);

    const [defaultTextTab, setDefaultTextTab] = useState('3D Model');
    useEffect(() => {
        // setProjectId(props.id);
        async function getProjectById() {
            const data_obj = {
                projectid: props.id
            }
            
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getProjectById`;

            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr.data.project', hxr.data.project)
            if(hxr.data.success && hxr.data.project){
                setLabeledNiraidText(hxr.data.project.labeledNiraIdText)                
                setLabeledNiraid(hxr.data.project.labeledNiraId)

                setLabeledNiraIdSecondText(hxr.data.project.labeledNiraIdSecondText)
                setLabeledNiraIdSecond(hxr.data.project.labeledNiraIdSecond)

                setLabeledNiraIdThirdText(hxr.data.project.labeledNiraIdThirdText)
                setLabeledNiraIdThird(hxr.data.project.labeledNiraIdThird)

                setIsGeolocated(hxr.data.project.isGeolocated)
                setHasExports(hxr.data.project.hasExports)

                let defaultTabText1 = '3D Labeled Model';
                let defaultTabText2 = '3D Labeled Model #2';
                // console.log('hxr.data.project.labeledNiraId.length',hxr.data.project.labeledNiraId.length)
                if(hxr.data.project.labeledNiraIdText && hxr.data.project?.labeledNiraIdText.length > 0 ){
                    setFirstTabText(hxr.data.project.labeledNiraIdText)
                }else{
                    setFirstTabText(defaultTabText1)
                }

                if(hxr.data.project.labeledNiraIdSecondText && hxr.data.project?.labeledNiraIdSecondText.length > 0 ){
                    setSecondTabText(hxr.data.project.labeledNiraIdSecondText)
                }else{
                    setSecondTabText(defaultTabText2)
                }

                if(hxr.data.project.defaultTextTab && hxr.data.project?.defaultTextTab.length > 0 ){
                    setDefaultTextTab(hxr.data.project.defaultTextTab)
                }

                if(hxr.data.project.labeledNiraIdThirdText && hxr.data.project?.labeledNiraIdThirdText.length > 0 ){
                    setThirtdTabText(hxr.data.project.labeledNiraIdThirdText)
                }

                if(hxr.data.project.labeledNiraIdForthText && hxr.data.project?.labeledNiraIdForthText.length > 0 ){
                    setForthTabText(hxr.data.project.labeledNiraIdForthText)
                }

                if(hxr.data.project.labeledNiraIdFifthText && hxr.data.project?.labeledNiraIdFifthText.length > 0 ){
                    setFifthTabText(hxr.data.project.labeledNiraIdFifthText)
                }
            }
            // console.log('projectMenu hxr-', hxr)
            
        }
        getProjectById();

        async function getDeliverableOrthoByProject() {
            console.log('getDeliverableOrthoByProject');
            const data_obj = {
                projectId: props.id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/deliverableAsset/getDeliverableAssetsByProjectId`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('getDeliverableOrthoByProject hxr',hxr)
            if(hxr.data.deliverableAsset && hxr.data.deliverableAsset.length >0){
                setHasOrthoMosaics(true);
            }
            // setOrthoList(hxr.data.deliverableAsset) 
            // console.log('setOrthoList', orthoList)           
        } getDeliverableOrthoByProject()

    }, [])


    const handleViewer = () => {        
        window.location.href = `/project/${props.id}/viewer`;
    }

    const handleFiles = () => {        
        window.location.href = `/project/${props.id}/edit`;
    }

    const handleDeliverables = () => {        
        window.location.href = `/project/${props.id}/deliverable`;
    }

    const handleLabeledViewer = () => {        
        window.location.href = `/project/${props.id}/labeledViewer`;
    }

    const handleSecondLabeledViewer = () => {        
        window.location.href = `/project/${props.id}/labeledViewerOptional`;
    }
    const handleProjectDeliverableOrtho = () => {        
        window.location.href = `/project/${props.id}/projectDeliverableOrtho`;
    }
    const handleGeoLocated = () => {        
        window.location.href = `/project/${props.id}/geolocated`;
    }
    const handleExports = () => {        
        window.location.href = `/project/${props.id}/exports`;
    }
    const handleNiraThird = () => {        
        window.location.href = `/project/${props.id}/viewer-model`;
    }

    const handleNiraForth = (id) => {        
        window.location.href = `/project/${props.id}/viewer-model/5`;
    }

    const handleNiraFifth = (id) => {        
        window.location.href = `/project/${props.id}/viewer-model/6`;
    }


    return (
        <>
        <ButtonGroup aria-label="Basic example" className="toolbarAbs" style={{width:"100%"}}>
            <Button variant="secondary" className={`backgroundGray ${props.active=='myfiles' ? "btnSelected" : ""}`} onClick={handleFiles}> My Files</Button>
            <Button variant="secondary" className={`backgroundGray ${props.active=='3dmodel' ? "btnSelected" : ""}`} onClick={handleViewer}>
                {defaultTextTab}
            </Button>
            {labeledNiraId && labeledNiraId.length > 0 ? 
                <Button variant="secondary" 
                        className={`backgroundGray ${props.active=='labeledviewer' ? "btnSelected" : ""}`} 
                        onClick={handleLabeledViewer}>{firstTabText}</Button> : null
            }
            {labeledNiraIdSecond && labeledNiraIdSecond.length > 0 ? 
                <Button variant="secondary" 
                        className={`backgroundGray ${props.active=='secondLabeledviewer' ? "btnSelected" : ""}`} 
                        onClick={handleSecondLabeledViewer}>{secondTabText}</Button> : null
            }
            {labeledNiraIdThird && labeledNiraIdThird.length > 0 ? 
                <Button variant="secondary" 
                        className={`backgroundGray ${props.active=='thirdLabeledviewer' ? "btnSelected" : ""}`} 
                        onClick={handleNiraThird}>{thirtdTabText ? thirtdTabText : '3D Model (Model 1)'}</Button> : null
            }
            {forthTabText && forthTabText.length > 0 ? 
                <Button variant="secondary" 
                        className={`backgroundGray ${props.active=='forthLabeledviewer' ? "btnSelected" : ""}`} 
                        onClick={handleNiraForth}>{forthTabText}</Button> : null
            }
            {fifthTabText && fifthTabText.length > 0 ? 
                <Button variant="secondary" 
                        className={`backgroundGray ${props.active=='fifthLabeledviewer' ? "btnSelected" : ""}`} 
                        onClick={handleNiraFifth}>{fifthTabText}</Button> : null
            }
            {/* <Button variant="secondary" className={`backgroundGray ${props.active=='3dLabeledmodel' ? "btnSelected" : ""}`} onClick={handleLabeledViewer}>Labeled 3D Model</Button> */}
            {/* {true ?  <Button variant="secondary" className={`backgroundGray ${props.active=='3dLabeledmodel' ? "btnSelected" : ""}`} onClick={handleSecondLabeledViewer}>Second Labeled 3D Model (more letters in the na)</Button> : null}             */}
            <Button variant="secondary" className={`backgroundGray ${props.active=='deliverable' ? "btnSelected" : ""}`} onClick={handleDeliverables}>Deliverables</Button>
            {hasOrthoMosaics && (
                <Button variant="secondary" className={`backgroundGray ${props.active=='ortho' ? "btnSelected" : ""}`} onClick={handleProjectDeliverableOrtho}>Orthomosaics</Button>
            )}
            {isGeolocated && (
                <Button variant="secondary" className={`backgroundGray ${props.active=='geo' ? "btnSelected" : ""}`} onClick={handleGeoLocated}>Pavement Data</Button>
            )}
            {hasExports && (
                <Button variant="secondary" className={`backgroundGray ${props.active=='export' ? "btnSelected" : ""}`} onClick={handleExports}>My Exports</Button>
            )}
        </ButtonGroup>
        </>
      
    );
};
export default ProjectMenu;

    