export const ParseStatus = (status) => {
    switch (status) {
        case "DRAFT":
            return "Draft";
        case "SUBMITTED":
            return "Submitted";
        case "PROCESSING":
        case "HOLD":
        case "MODELING_START":
        case "MODELING_READY_FOR_REVIEW":
        case "MODELING_MANUAL_REVISING":
            return "Processing";
        case "MODELING_COMPLETED":
            return "3D Model Published";
        case "CHECK_GSD":
            return "3D Model Published";
        case "CUSTOMER_RESHOOTING":
            return "Data Reshooting in Progress";
        case "QAQC":
        case "MODEL_LABELING":
            return "3D Model Published";
        case "RESHOOTING_DATA":
            return "Ask to Reshoot Data Due to Unsatisfactory Resolutiona";
        case "COMPLETED":        
            return "Completed";
        default:
            return "Processing";
    }
    }