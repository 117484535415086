import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton } from 'react-bootstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/header';
import closebtn from '../images/close-btn.svg';
import profilePlaceholder from '../images/user-profile-tools.svg';
import { API_URL } from '../constants';
import { useAuthState, useAuthDispatch, logout } from '../context'
import backarrow from '../images/backarrow.svg';

const UpdatePassword = () => {
    const [name, setName] = useState(() => {
        const saved = localStorage.getItem('currentUser');
        const initialValue = JSON.parse(saved);
        return initialValue.user.name || "";
      });
      const [user, setUser] = useState(() => {
        const saved = localStorage.getItem('currentUser');
        const initialValue = JSON.parse(saved);
        return initialValue.user.email || "";
      });
    const [email, setEmail] = useState(() => {
        const saved = localStorage.getItem('currentUser');
        const initialValue = JSON.parse(saved);
        return initialValue.user.email || "";
    });

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('New Password is required')
            .min(6, 'Password must be at least 6 characters'),
        currentPassword: Yup.string()
            .required('Current Password is required')
            .min(6, 'Password must be at least 6 characters'),
        newPassword: Yup.string().oneOf([Yup.ref('password'), null], "Passwords don't match")
            .required('Confirmation Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    async function onSubmit(data) {
        console.log('onSubmit', data);
        console.log('user',user);
        const data_obj = {
            password: data.currentPassword,
            email: email,
            newPassword: data.newPassword
        }
        console.log('data_obj',data_obj);
        const config = {
            headers: {
                'Content-Type': 'x-www-form-urlencoded',
            },
        };
        const url = `${API_URL}/users/userUpdatePassword`;

        let hxr = await axios(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: data_obj
        })
        .catch(error => {
            console.log('error', error);
            Swal.fire({
                title: 'Error!',
                text: 'An error occured, please try again later',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        });

        // const _data = await hxr.data;
        // console.log('data', _data);

        if (hxr.data && hxr.data.success) {
            // localStorage.setItem('fwdpwd_email', data.user.email);
            // props.history.push('/login')
            //trigger swal  
            Swal.fire({
                title: 'Success!',
                text: 'Profile updated successfully',
                icon: 'success',
                confirmButtonText: 'OK'
            })
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'An error occured, please try again later',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        }

    }

    const dispatch = useAuthDispatch()

    const handleGotoDashboard = () => {
       window.location.href = '/dashboard';
    }

    

    return (
        <>
            <Header />
            <Container >
                <Row>
                    <Col md="12" >
                        <div className="project-dashboard">
                            <div className="profile-actions">
                                <div className="project-actions-left left"> 
                                    <Button variant="link" className="danger-link" onClick={handleGotoDashboard}><img src={backarrow} width="30px" /></Button>
                                 </div>
                                <div className="project-actions-right right">
                                    {/* <Button className="close-btn" onClick={handleLogout}>
                                        <img src={closebtn} />
                                    </Button> */}
                                </div>
                            </div>
                            <div className="profile-center">
                                <p className="left bottom-50">
                                    <img src={profilePlaceholder} />
                                    
                                </p>
                                <br />
                                <h1>Change Password</h1>
                                <form onSubmit={handleSubmit(onSubmit)} className="action-link-auth">
                                <div className="form-group">
                                        <label htmlFor="password" className="label-custom">Current Password</label>
                                        <input
                                            type="password"
                                            name="currentPassword"
                                            id="currentPassword"
                                            autoComplete="off"
                                            className={`password input ${errors.password ? 'is-invalid' : ''}`}
                                            {...register('currentPassword')}
                                        // required value={password} onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div className="invalid-feedback">{errors.currentPassword?.message}</div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="password" className="label-custom">New Password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            autoComplete="off"
                                            className={`password input ${errors.password ? 'is-invalid' : ''}`}
                                            
                                            {...register('password')}
                                        // required value={password} onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div className="invalid-feedback">{errors.password?.message}</div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="password" className="label-custom">Confirm Password</label>
                                        <input
                                            type="password"
                                            name="newPassword"
                                            id="newPassword"
                                            autoComplete="off"
                                            className={`password input ${errors.newPassword ? 'is-invalid' : ''}`}
                                            {...register('newPassword')}
                                        // required value={password} onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div className="invalid-feedback">{errors.newPassword?.message}</div>
                                    </div>
                                    
                                    <Button
                                        variant="primary"
                                        className="primary-btn update-profile-btn"
                                        type="submit"
                                    >Update</Button>
                                    
                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default UpdatePassword;