import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton,Form } from 'react-bootstrap';
import BridgeCardImg from '../images/BridgeCard.svg';
import BuildingFacadeCardImg from '../images/BuildingFacadeCard.svg';
import CoolingTowerCardImg from '../images/CoolingTowerCard.svg';
import CulvertCardImg from '../images/CulvertCard.svg';
import DamCardImg from '../images/DamCard.svg';
import IndustrialChimneyCardImg from '../images/IndustrialChimneyCard.svg';
import LeveeChimneyCardImg from '../images/LeveeCard.svg';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import WindPowerIcon from '@mui/icons-material/WindPower';

const RenderImage = (props) => {
    let imgSrc = '';
    let renderIcon = true;
    // eslint-disable-next-line default-case
    switch(props.projectType){
        case '1':
        case '8':
            imgSrc = BridgeCardImg;
            break; 
        case '2':
            imgSrc = BuildingFacadeCardImg;
        break; 
        case '3':
            imgSrc = CoolingTowerCardImg;
        break; 
        case '4':
            imgSrc = IndustrialChimneyCardImg;
            break; 
        case '5':
            imgSrc = DamCardImg;
            break; 
        case '6':
            imgSrc = LeveeChimneyCardImg;
            break; 
        case '7':
            imgSrc = CulvertCardImg;
            break;     
        case '9':
            renderIcon = false;
            imgSrc = <span className="dashboardIcon"><FlightTakeoffIcon /></span>;
            break;     
        case '10':
            renderIcon = false;
            imgSrc = <span className="dashboardIcon"><AddRoadIcon /></span>;
            break;  
        case '11':
            renderIcon = false;
            imgSrc = <span className="dashboardIcon"><WindPowerIcon /></span>;
            break;        
           
    }
    return (
        <>  
        {renderIcon ? (
            <img src={imgSrc} width={props.size} />
        ):(
            imgSrc
        )}
           
        </>
    )
}
export default RenderImage;

    